import React from 'react';
import Page from '../components/Page';
import Section from '../components/Section';
import VideoComponent from '../components/VideoComponent';

const Video = () => {

  return (
    <Page title='Video'>
      {/* Hero */}
      <Section wide>
        <VideoComponent
          videoSrcURL="https://vimeo.com/733118964/c4f2abfad4"
          videoTitle="Innowell for Individuals"
        />
      </Section>
    </Page>
  )
};

export default Video;
