import React from "react"
const VideoComponent = ({ videoSrcURL, videoTitle, ...props }) => (
    <>
        <div style={{ padding:"56.25% 0 0 0", position:"relative" }}>
            <iframe 
                src="https://player.vimeo.com/video/733118964?h=c4f2abfad4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
                frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowfullscreen 
                style={{ position:"absolute", top:0, left:0, width:"100%", height:"100%" }} 
                title="Unlock Better Mental Health Care | Innowell">
            </iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
    </>);
export default VideoComponent